import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calender from "../Calender";
import { getBackendUrl } from "../../constants";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../CustomSelect/Index";

const CompanyData = () => {

  const SESSION_ID = localStorage.getItem("Session_Id")
  const navigate = useNavigate();
  const location = useLocation();
  const { orderbook_enabled, payment_alert_date, terminate_date, freeze_date } = location.state;
  const initialValues = { orderbook_enabled, payment_alert_date, terminate_date, freeze_date };
  const { control, handleSubmit } = useForm({ defaultValues: initialValues });

  function handleClose() {
    navigate(-1); // Navigates back to the previous page
  }

  //created a function to update Customer data
  const onSubmit = async (data) => {
    const confirmUpdate = window.confirm(
      "Are you sure you want to update the company data ?"
    );
    if (!confirmUpdate) {
      return;
    }

    try {
      const reqBody = { ...data, id: location.state.id }
      await axios.post(`${getBackendUrl()}/v1/admin-portal/updateCompany`, reqBody, { headers: { "Session-Id": SESSION_ID } });

      // Show success
      toast.success("Data Update successful!", {});
      setTimeout(() => {
        navigate(-1);
      }, 1200);
    } catch (error) {
      console.error("Data Update Error:", error);
      toast.error("Data not Updated. Please try again.");
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <SideBar />
      <section className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-5 ml-[200px]">
        <div style={{ border: "1px solid #D4D4D4", padding: "24px", borderRadius: "8px", backgroundColor: "white", boxShadow: "1px 1px 1px 5px #F0F0F0" }}>
          <form onSubmit={formSubmit}>
            <h1 className="text-center text-[32px] text-sky-600 ">
              <span id="mark">Company Data</span>
            </h1>
            <Controller
              name="payment_alert_date"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <div style={{ marginTop: 16 }}>
                    <p className="text-[16px] py-2 text-black font-bold">
                      Payment Alert Date
                    </p>
                    <Calender value={value} onChange={onChange} />
                  </div>
                )
              }}
            />
            <Controller
              name="freeze_date"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <div style={{ marginTop: 16 }}>
                    <p className="text-[16px] py-2 text-black font-bold">
                      Freeze Date
                    </p>
                    <Calender value={value} onChange={onChange} />
                  </div>
                )
              }}
            />
            <Controller
              name="terminate_date"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <div style={{ marginTop: 16 }}>
                    <p className="text-[16px] py-2 text-black font-bold">
                      Terminate Date
                    </p>
                    <Calender value={value} onChange={onChange} />
                  </div>
                )
              }}
            />
            <Controller
              name="orderbook_enabled"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <div style={{ marginTop: 16 }}>
                    <p className="text-[16px] text-black py-2 font-bold">
                      OrderedBook Enabled
                    </p>
                    <CustomSelect
                      value={value}
                      onChange={onChange}
                      options={[{ label: "No", value: false }, { label: "Yes", value: true }]}
                    />
                  </div>
                )
              }}
            />
            <div style={{ marginTop: 16 }}>
              <button
                className="inline-flex w-[180px] items-center justify-center px-8 py-4 mt-6  font-sans font-semibold tracking-wide text-white bg-blue-500 hover:bg-blue-600 rounded-lg h-[50px]"
                onClick={handleSubmit(onSubmit)}
              >
                Update Data
              </button>

              <button
                className="inline-flex ml-4 my-2 w-[150px] items-center justify-center px-8 py-4 font-sans font-semibold tracking-wide text-white bg-red-500 hover:bg-red-600  rounded-lg h-[50px]"
                onClick={handleClose}
              >
                CLOSE
              </button>
            </div>
          </form>
        </div>
      </section >
    </>
  );
};

export default CompanyData;
