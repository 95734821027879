import React, { useEffect, useRef, useState } from "react";
import logo from "../../src/images/logo-with-text.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import waves from "../images/Waves.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import { getBackendUrl } from "../constants";
import { Controller, useForm } from "react-hook-form";

const Login = () => {
  const navigate = useNavigate();
  const [reqId, setReqId] = useState();
  const { control, getValues } = useForm({});

  useEffect(() => {
    const sessionId = localStorage.getItem("Session_Id");
    if (sessionId) {
      navigate(-1);
    }
  }, []);

  const handleClickGetLoginOtp = async () => {
    const email = getValues("email");
    if (!email) {
      toast.error("Email cannot be blank.");
      return;
    }
    try {
      const response = await axios.post(`${getBackendUrl()}/v1/admin-portal/getLoginOtp`, {
        email
      });
      setReqId(response.data.reqId);
      toast.success("Sent OTP successfully!", {
        position: "top-center",
      });
    } catch (error) {
      toast.error(error.response.data?.msg || JSON.stringify(error.response.data))
    }
  }

  const handleClickVerifyLoginOtp = async () => {
    const otp = getValues("otp");
    if (!otp) {
      toast.error("OTP cannot be blank.");
      return;
    }

    try {
      const response = await axios.post(`${getBackendUrl()}/v1/admin-portal/verifyLoginOtp`, {
        reqId,
        otp,
      });
      localStorage.setItem(
        "user_name",
        `${response.data.fname} ${response.data.lname}`
      );
      localStorage.setItem("Session_Id", response.data.session_id);

      toast.success("Login successful!", {
        position: "top-center",
        autoClose: 700,
      });
      setTimeout(() => navigate("/Home"), 800);
    } catch (error) {
      toast.error(error.response.data?.msg || JSON.stringify(error.response.data))
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={900}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex justify-center min-h-screen p-6 sm:p-16">
        <div className="flex flex-col items-center max-w-md w-full rounded-md p-6 sm:p-10">
          <img
            src={logo}
            className="w-[250px] sm:w-[350px] h-auto"
            alt="Logo"
          />
          <h1 className="text-[18px] sm:text-[20px] text-gray-700 font-semibold text-center mt-4">
            Welcome back
          </h1>
          <p className="text-gray-500 text-[14px] sm:text-[16px] font-semibold text-center mt-2">
            Continue with your email and OTP
          </p>
          <Box
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: "42ch",
                marginTop: "25px",
              },
            }}
          >
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    label="Email &nbsp;"
                    value={value}
                    onChange={onChange}
                    autoFocus={true}
                  />
                )
              }}
            />
            {reqId && (
              <Controller
                name="otp"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      label="OTP &nbsp;"
                      onChange={onChange}
                      value={value}
                      type="password"
                    />
                  )
                }}
              />
            )}
          </Box>
          {!reqId && (
            <button
              className="bg-[#00A0E3] text-white h-12 w-[360px] text-[15px] sm:text-[17px] font-bold rounded-md mt-6"
              onClick={() => handleClickGetLoginOtp()}
            >
              Get Login OTP
            </button>
          )}
          {reqId && (
            <button
              className="bg-[#00A0E3] text-white h-12 w-[360px] text-[15px] sm:text-[17px] font-bold rounded-md mt-6"
              onClick={() => handleClickVerifyLoginOtp()}
            >
              Verify OTP
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: 300,
          left: 0,
          width: "100vw",
          height: "80vh",
          backgroundImage: `url(${waves})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></div>
    </>
  );
};

export default Login;
