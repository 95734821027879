import Select from 'react-select';

export default function CustomSelect({ options, value, onChange, ...rest }) {
  return (
    <Select
      styles={{ control: (base) => ({ ...base, height: "42px", border: "1px solid #e2e8f0" }) }}
      options={options}
      value={options.find(obj => obj.value == value)}
      onChange={(option) => onChange(option?.value)}
      {...rest}
    />
  )
};