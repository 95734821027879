import React from 'react'

const Calender = ({ value, onChange }) => {
  return (
    <div>
      <input
        type="date"
        value={value || ""}
        style={{ width: "100%", height: 42 }}
        className="border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
        onChange={(e) => onChange(e.target.value || null)
        }
      />
    </div>
  )
}

export default Calender
