import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../Sidebar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../CustomSelect/Index";
import { getBackendUrl } from "../../constants";
import Calender from "../Calender";

const Customer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companies, users, ...customerData } = location.state;
  const { control, handleSubmit } = useForm({ defaultValues: customerData });

  function handleClose() {
    navigate(-1);
  }

  const SESSION_ID = localStorage.getItem("Session_Id");
  //created a function to uddate Customer data
  const onSubmit = async (data) => {
    try {
      await axios.post(
        `${getBackendUrl()}/v1/admin-portal/updateCustomer`, data, { headers: { "Session-Id": SESSION_ID } }
      );
      toast.success("Data Update successful!", {});
      setTimeout(() => {
        navigate(-1);
      }, 800);
    } catch (error) {
      toast.error("Data not Updated. Please try again.");
    }
  };

  return (
    <>
      <SideBar />
      <ToastContainer
        position="top-center"
        autoClose={400}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="flex flex-col items-center justify-center lg:ml-[240px] px-4 py-6">
        <div className="bg-white border-sky-600 w-full max-w-6xl p-4">
          <h1 className="text-center text-2xl md:text-3xl text-sky-600 mb-6 ">
            <span id="mark">Customer Data</span>
          </h1>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="id"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="mb-4">
                    <label className="block text-sm font-bold text-gray-700">
                      Customer ID
                    </label>
                    <input
                      value={value}
                      onChange={onChange}
                      disabled
                      type="text"
                      placeholder="Enter Your content"
                      className="w-full md:w-1/3 border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent text-gray-500"
                    />
                  </div>
                )
              }}
            />
            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Customer Name
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Customer name"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="address"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Address
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Your content"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[100px]">
                      <label className="block text-sm font-bold text-gray-700">
                        City
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Your content"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
            </div>

            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="refer_by"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Refer By
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter referral name"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="admin_name"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Admin
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Admin name"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="admin_mobile"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[150px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Admin Number
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="number"
                        placeholder="Mobile Number"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="installation_price"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Installation Price
                      </label>
                      <input
                        value={String(value ?? "")}
                        onChange={(e) => onChange(e.target.value === "" ? null : Number(e.target.value))}
                        type="number"
                        placeholder="Enter Installation Price"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="renewal_price"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Renewal Price
                      </label>
                      <input
                        value={String(value ?? "")}
                        onChange={(e) => onChange(e.target.value === "" ? null : Number(e.target.value))}
                        type="number"
                        placeholder="Enter amounts"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
            </div>

            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="invoices_limit"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Invoices Limit
                      </label>
                      <input
                        value={String(value ?? "")}
                        onChange={(e) => onChange(e.target.value === "" ? null : Number(e.target.value))}
                        type="number"
                        placeholder="Enter in Digits"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="companies_limit"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Companies Limit
                      </label>
                      <input
                        value={String(value ?? "")}
                        onChange={(e) => onChange(e.target.value === "" ? null : Number(e.target.value))}
                        type="number"
                        placeholder="Enter in Digits"
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px] gap-4">
                      <p className="block text-sm font-bold text-gray-700">
                        Status
                      </p>
                      <CustomSelect
                        value={value}
                        onChange={onChange}
                        options={[
                          { label: "TRIAL", value: "TRIAL" },
                          { label: "ACTIVE", value: "ACTIVE" },
                          { label: "INACTIVE", value: "INACTIVE" },
                        ]}
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="trial_end_date"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px] gap-4">
                      <p className="block text-sm font-bold text-gray-700">
                        Trial End Date
                      </p>
                      <Calender value={value} onChange={onChange} />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="orderbook_enabled"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px] gap-4">
                      <p className="block text-sm font-bold text-gray-700">
                        Order Book Enabled
                      </p>
                      <CustomSelect
                        value={value}
                        onChange={onChange}
                        options={[{ label: "No", value: false }, { label: "Yes", value: true }]}
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="advanced_user_management_enabled"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px] gap-4">
                      <p className="block text-sm font-bold text-gray-700">
                        Advanced User Management
                      </p>
                      <CustomSelect
                        value={value}
                        onChange={onChange}
                        options={[{ label: "No", value: false }, { label: "Yes", value: true }]}
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex flex-wrap gap-4 mb-4">
              <Controller
                name="handler"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Handle By
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="textarea"
                        placeholder="Enter Handle By.."
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="remarks"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className="flex-1 min-w-[200px]">
                      <label className="block text-sm font-bold text-gray-700">
                        Remarks
                      </label>
                      <input
                        value={value}
                        onChange={onChange}
                        type="textarea"
                        placeholder="Enter Remarks..."
                        className="w-full border border-slate-200 rounded-lg py-2 px-4 outline-none bg-transparent"
                      />
                    </div>
                  )
                }}
              />
            </div>
            <div className="flex flex-wrap gap-4">
              <button
                type="submit"
                className="w-full md:w-[250px] py-3 px-4 font-semibold text-white bg-blue-500 hover:bg-blue-600 rounded-lg"
              >
                Update Data
              </button>

              <button
                className="w-full md:w-[200px] py-3 px-4 font-semibold text-white bg-red-500 hover:bg-red-600 rounded-lg"
                onClick={handleClose}
              >
                CLOSE
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Customer;
